
















import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Component
export default class AddNumbers extends Vue {
  private numbers = [] as string[];
  private status = "";
  private handleFileUpload(){
    console.log("file uploaded");
    const file = (this.$refs.file! as HTMLInputElement).files![0];
    const reader = new FileReader();
    reader.onload = (e)=>{
      const result = e.target?.result?.toString();
      this.numbers = result!.split("\n");
    }
    reader.readAsText(file);
  }

  async add(){
    const batchArray: firebase.firestore.WriteBatch[] = [];
    batchArray.push(firebase.firestore().batch()!);
    var batchIndex = 0;
    var cnt = 0;

    this.numbers.forEach((num)=>{
      const ref = firebase.firestore().collection("uniqueNumbers").doc();
      batchArray[batchIndex].set(ref,{number: num, used: false})
      cnt++;
      if(cnt === 499){
        batchArray.push(firebase.firestore().batch());
        batchIndex++;
        cnt=0;
      }
    });
    console.log(batchArray);
    this.status = "処理開始";
    batchArray.forEach(async batch => await batch.commit());
    this.status = "処理完了";
    this.numbers = [];
  }
}
